export interface IClub {
  id: number;
  tunnus: string;
  name: string;
}

export const clubs = [
  { id: 4, tunnus: "SUS", name: "Susirajan Petanque ry" },
  { id: 5, tunnus: "BAL", name: "Boule Baltique ry" },
  { id: 10, tunnus: "KAR", name: "Karhu-Petankki ry" },
  { id: 11, tunnus: "KOK", name: "Koski-Kuula ry" },
  { id: 13, tunnus: "HBY", name: "Helsingin Boule-yhdistys ry" },
  { id: 15, tunnus: "BIT", name: "Bitankki ry" },
  { id: 17, tunnus: "KAL", name: "Kallion Kirkas ry" },
  { id: 23, tunnus: "MAP", name: "MAP Räime ry" },
  { id: 25, tunnus: "LIN", name: "Linnan Petanquistit ry" },
  { id: 27, tunnus: "MPC", name: "Mikkelin Petanque Club ry" },
  { id: 31, tunnus: "PKP", name: "Petanque Kuopio Petku ry" },
  { id: 35, tunnus: "LOI", name: "Loimaan Petanque ry" },
  { id: 37, tunnus: "ORA", name: "Oravaisten Petanque ry" },
  { id: 38, tunnus: "BOU", name: "Boulistit ry" },
  { id: 41, tunnus: "PCP", name: "Petanque Club de Pori ry" },
  { id: 43, tunnus: "EPA", name: "Espoon Patonki ry" },
  { id: 50, tunnus: "KVP", name: "KOO-VEE ry / Petanque-jaosto" },
  { id: 52, tunnus: "TPQ", name: "Tampere Petanque TPQ ry" },
  { id: 54, tunnus: "NAS", name: "Nassu ry" },
  { id: 55, tunnus: "TUR", name: "Turun Petanque-Seura ry" },
  { id: 56, tunnus: "PSC", name: "PSC Järvenpää ry" },
  { id: 70, tunnus: "MYN", name: "Mynämäen Petanque-seura ry" },
  { id: 77, tunnus: "VUF", name: "V- ja U-seura Femma ry" },
  { id: 91, tunnus: "NAL", name: "Petosen Nallet ry" },
  {
    id: 92,
    tunnus: "UTU",
    name: "Uotilan Työväen Urheilijat ry / UTU-Petankki",
  },
  { id: 100, tunnus: "KOP", name: "Koski-Petankki ry" },
  { id: 105, tunnus: "RIE", name: "Riemukaari ry" },
  { id: 123, tunnus: "KAN", name: "Savo-Karjalan Petanque-seura Kanuunat ry" },
  { id: 128, tunnus: "TYR", name: "Tyrvää Petanque ry" },
  { id: 129, tunnus: "KIS", name: "Kalvolan Isku ry" },
  { id: 130, tunnus: "KAU", name: "Karkkilan Urheilijat ry / Petankkijaosto" },
  { id: 131, tunnus: "CDC", name: "Club des Champions ry" },
  { id: 132, tunnus: "PKK", name: "Petankki Seura Karjala Kuula ry" },
  { id: 136, tunnus: "ERP", name: "Euran Raiku ry / petankkijaosto" },
  { id: 137, tunnus: "JYV", name: "V- ja U-seura Jyväskylän Valo ry" },
  { id: 142, tunnus: "KUU", name: "Kuulas Helsinki ry" },
  { id: 143, tunnus: "KOR", name: "Korson Kaiku ry" },
  { id: 144, tunnus: "KUP", name: "Kouvolan Urheilupetanque ry" },
  { id: 145, tunnus: "KEM", name: "Kemi Petankki ry" },
  { id: 146, tunnus: "LEM", name: "Lempäälän Jyry ry" },
  { id: 147, tunnus: "HPQ", name: "Helsinki Petanque ry" },
  { id: 148, tunnus: "VOI", name: "Voisalmen Kunto VoiKu Ry" },
  { id: 149, tunnus: "PCU", name: "PCU Sastamala ry" },
  { id: 151, tunnus: "SGK", name: "SGK Boule Club ry" },
  { id: 152, tunnus: "SAL", name: "Salon Viesti ry Petanque" },
  { id: 153, tunnus: "NII", name: "Petankkiharrastajat Niittymaa ry" },
  { id: 154, tunnus: "LKI", name: "Linurin Kilikki ry" },
  { id: 155, tunnus: "JAP", name: "Jalkaranta Petanquen" },
  { id: 997, tunnus: "HAA", name: "Hämeen aluetoimikunta"},
  { id: 998, tunnus: "L-S", name: "Länsi-Suomen aluetoimikunta"},
  { id: 999, tunnus: "LIITTO", name: "Suomen Petanque Liitto Ry" },
];

export const getAllShortClubs = (): string[] => {
  return clubs
    .sort((a, b) => (a.name < b.name ? -1 : a > b ? 1 : 0))
    .map((c) => c.tunnus);
};
